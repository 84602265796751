import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { AuthService } from '@services/auth.service';
import { LoginUser } from '@entity/loginuser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  userName: string;
  isLogin: boolean;
  pageName: string;

  constructor(
    private router: Router,
    public loading: LoadingService,
    private authService: AuthService,
    public loginUser: LoginUser,
  ) {
  }

  getUsername = (value: LoginUser) => {
    if (value && 'attributes' in value) {
      this.userName = value.attributes.name || value.attributes.email;
    }
  }

  ngOnInit() {
  }


  onActivate() {
    this.pageName = this.router.url;
    this.authService.getCurrentUserInfo().then(this.getUsername);
  }

  /**
   * ログアウトボタン押下
   */
  logout() {
    this.authService.logOut();
    location.reload();
  }
}
