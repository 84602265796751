import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading = false;

  start(): void {
    this.loading = true;
  }

  end(): void {
    this.loading = false;
  }

  isLoading(): boolean {
    return this.loading;
  }
  loading_end = () => {
    this.loading = false;
  }
}
