import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'equal'
})
export class CommonPipe implements PipeTransform {
    transform(value: string | boolean, equal: string, true_text: string, false_text: string): string {
        if (typeof value === 'undefined') {
            // ロード完了前は空文字とする
            return '';
        } else if (value === equal) {
            return true_text;
        } else {
            return false_text;
        }
    }
}
