import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ListComponent } from '@screen/camera/list/list.component';
import { DetailComponent } from '@screen/camera/detail/detail.component';
import { ListComponent as LensListComponent } from '@screen/lens/list/list.component';
import { DetailComponent as LensDetailComponent } from '@screen/lens/detail/detail.component';
import { ChartsModule } from 'ng2-charts-x';
import { LoginComponent } from './screen/auth/login/login.component';
import { AuthGuard } from '@guard/auth.guard';
import { ApiService } from '@services/api.service';
import { ModalComponent } from '@screen/modal/text/modal.component';
import { Select2Module } from 'ng2-select2';
import { CommonPipe } from './pipes/common.pipe';
import { ImageComponent } from './screen/modal/image/image.component';
import { ChangePasswordComponent } from './screen/auth/changepassword/changepassword.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListComponent,
    LensListComponent,
    LensDetailComponent,
    DetailComponent,
    ModalComponent,
    ImageComponent,
    CommonPipe,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    Select2Module
  ],
  entryComponents: [
    ModalComponent,
    ImageComponent,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
