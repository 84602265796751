import { MdbTableDirective } from 'angular-bootstrap-md';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ChartService } from '@services/chart.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { ModalComponent } from '@screen/modal/text/modal.component';
import { ImageComponent } from '@screen/modal/image/image.component';
import { getImageSize, DateToString } from '@lib/lib';
import { Debug } from '@lib/log';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective;

  item_code: string;

  detail: any = [];
  images = {
    status: 'enable',
    datas: []
  };
  sizes: any = [];
  scores: any = [];
  continuation_token: string;
  table_columns = ['相場表コード', 'メーカ名', '機種名', '学習枚数', '最新の判定精度', '判定対象'];
  page_type = 'camera';

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private chartService: ChartService,
    private loading: LoadingService,
    private modalService: ModalService,
  ) {
    this.loading.start();
  }

  ngOnInit() {
    this.item_code = this.route.snapshot.paramMap.get('id');
    this.getResult();
    this.getScore(-7);
  }

  ngOnDestroy() {
    this.modalService.end();
  }

  getResult() {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/results/' + this.item_code;
    const param = { type: this.page_type };

    // API実行後レンダリング
    const mapping = (value: any) => {
      this.detail = value;
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      Debug().log(value);
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
    };

    this.apiService.getApi(path, param)
      .then(mapping).catch(error).finally(this.loading.loading_end);
  }

  getImage(token: string = null) {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/images/' + this.item_code;
    const param = { type: this.page_type };
    if (token) {
      param['next_key'] = token;
    }

    // API実行後レンダリング
    const mapping = (values: any) => {

      for (const value of Object.values(values.images)) {

        getImageSize(value['path']).then(
          result => {
            value['width'] = result['width'];
            value['height'] = result['height'];
          }
        );
        // 画像サイズ取得
        this.images.datas = this.images.datas.concat(value);
      }


      if ('next_key' in values) {
        this.continuation_token = values['next_key'];
        this.images.status = 'start';
      } else {
        this.continuation_token = null;
        this.images.status = 'end';
      }
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      Debug().log(value);
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
    };

    this.apiService.getApi(path, param)
      .then(mapping).catch(error).finally(this.loading.loading_end);
  }

  getScore(time: number) {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/score/' + this.item_code;
    const param = {
      type: this.page_type,
      from_date: DateToString(time),
      to_date: DateToString()
    };

    // API実行後レンダリング
    const mapping = (value: any) => {
      const details = [];
      details.push(value['score_front']);
      details.push(value['score_back']);
      details.push(value['score_under']);
      this.scores = this.chartService.mappingDetail(details, 'param');
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      Debug().log(value);
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
    };

    this.apiService.getApi(path, param)
      .then(mapping).catch(error).finally(this.loading.loading_end);
  }

  delete = () => {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/results/' + this.item_code;
    const param = { type: this.page_type };

    // API実行後レンダリング
    const apiAfter = () => {
      window.location.href = '/';
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
      Debug().log(value);
    };

    this.apiService.delApi(path, param)
      .then(apiAfter).catch(error).finally(this.loading.loading_end);
  }

  update = () => {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/results/' + this.item_code;
    const active = String(!this.detail.active);
    const param = { active: active, type: this.page_type };

    // API実行後レンダリング
    const apiAfter = () => {
      window.location.reload();
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
      Debug().log(value);
    };

    this.apiService.putApi(path, param)
      .then(apiAfter).catch(error).finally(this.loading.loading_end);
  }

  openDeleteModal() {
    this.modalService.create(ModalComponent);
    this.modalService.set(
      {
        title: 'カメラ機種削除',
        body: 'このデータを削除します。よろしいでしょうか？',
        is_action: true,
        button: '削除'
      }
    );
    this.modalService.action(this.delete);
    this.modalService.end();
  }

  openUpdateModal() {
    this.modalService.create(ModalComponent);
    let param: {};
    if (this.detail.active) {
      param = {
        title: 'カメラ機種　無効化',
        body: 'このデータを無効化します。よろしいでしょうか？',
        is_action: true,
        button: '更新'
      };
    } else {
      param = {
        title: 'カメラ機種　有効化',
        body: 'このデータを有効化します。よろしいでしょうか？',
        is_action: true,
        button: '更新'
      };
    }
    this.modalService.set(param);
    this.modalService.action(this.update);
    this.modalService.end();
  }

  imageModal(path: string) {
    this.modalService.create(ImageComponent, { class: 'modal-lg' });
    const param = {
      image: path
    };
    this.modalService.set(param);
    this.modalService.end();
  }
}
