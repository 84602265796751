import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { TableService } from '@services/table.service';
import { ChartService } from '@services/chart.service';
import { LoadingService } from '@services/loading.service';
import { SearchFormService } from '@services/search.form.service';
import { ModalService } from '@services/modal.service';
import { ModalComponent } from '@screen/modal/text/modal.component';
import { Debug } from '@lib/log';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective) mdbTable: MdbTableDirective;

  headElements = [
    {
      key: 'no',
      value: 'No',
    },
    {
      key: 'item_code',
      value: '相場表コード',
    },
    {
      key: 'manufacturer_name',
      value: 'メーカ名',
    },
    {
      key: 'item_name',
      value: '機種名',
    },
    {
      key: 'image_counts',
      value: '学習枚数',
    },
    {
      key: 'score',
      value: '最新の判定精度',
    },
    {
      key: 'active',
      value: '判定対象',
    },
  ];

  page_type = 'lens';

  error_message: string;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public tableService: TableService,
    private chartService: ChartService,
    private loading: LoadingService,
    public searchFormService: SearchFormService,
    private modalService: ModalService,
  ) {
    this.loading.start();
  }

  /**
   * 詳細画面遷移（通常の routerlinkはなぜか利用不可）
   * @param item_code 機種コード
   */
  clicked(item_code: string) {
    this.router.navigate(['/lens/' + item_code]);
  }

  ngOnInit() {
    // 画面が更新されない限り、一度だけメーカーと機種の一覧は取得できる。
    if (this.searchFormService.makers[this.page_type].list.length === 0) {
      this.searchFormService.getItmes(this.page_type).then(this.loading.loading_end);
    } else {
      // すでにメーカー機種の一覧が取得済みであれば、API発行しない。
      setTimeout(this.loading.loading_end, 100);
    }

    // 戻るボタン遷移時以外はデータの初期化
    if (this.tableService.data_type !== this.page_type) {
      this.searchFormService.item_options.disabled = true;
      this.tableService.clear();
      this.tableService.data_type = this.page_type;
      this.searchFormService.clear();
    }
  }

  get() {
    // API実行前処理
    this.loading.start();

    // API実行先
    const path = 'ml/training/results';
    const valid = this.searchFormService.valid_parameters(this.page_type);
    this.error_message = valid.error;
    if (valid.error) {
      this.loading.end();
      return;
    }
    // API実行後レンダリング
    const mapping = (datas: any) => {
      this.tableService.clear();
      this.tableService.row_datas = datas;
      if (this.tableService.row_datas) {
        this.chartService.mappingList(this.tableService.row_datas, 'score_histories');
      }
      this.tableService.init(10);
    };

    // API実行エラー
    const error = (value: PromiseConstructor = null) => {
      Debug().log(value);
      this.modalService.create(ModalComponent);
      this.modalService.set(
        {
          title: 'エラーが発生しました。',
          body: '問題が解決しない場合は、管理者にお問い合わせください。\nエラー原因：API接続エラー',
          is_action: false
        }
      );
      this.modalService.end();
    };

    this.apiService.getApi(path, valid.parameters)
      .then(mapping).catch(error).finally(this.loading.loading_end);
  }

  /**
   * メーカーのセレクトボックスを切り替え
   * @param manufacturer_id メーカーのID
   */
  changeItem(manufacturer_id: string) {
    this.searchFormService.changeItem(manufacturer_id, this.page_type);
  }

  /**
   * セレクトボックスで選択した機種コード
   * @param item_code_by_selectbox 機種コード
   */
  selectItem(item_code_by_selectbox: string) {
    this.searchFormService.selectItem(item_code_by_selectbox);
  }

  /**
   * ソート後、ページャーをリセットする
   * @param key ソートキー
   */
  sort(key: string) {
    this.tableService.sort(key, this.tableService.datas);
    this.tableService.setPage(1);
  }
}
