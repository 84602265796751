import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { sortDict, isEmpty } from '@lib/lib';
import { ErrorMessage } from '@entity/errorMessage';

@Injectable({
  providedIn: 'root'
})

export class SearchFormService {

  // 検索フォームタイプ1:機種で検索 , 2:精度で検索
  viewGroup = 1;

  // メーカーコード
  manufacturer_id: string;

  // 相場表コード（テキストボックス）
  item_code: string;

  // 相場表コード（機種のセレクトボックスで）
  item_code_by_selectbox: string;

  // 精度（テキストボックス）
  score: number;

  // 精度の方向（セレクトボックス）up:「以上」down:「以下」
  score_type: string;

  public manufacturer_options: any;
  public item_options: any;
  items = [];
  makers = {
    'camera': {
      'api_end': false,
      'list': []
    },
    'lens': {
      'api_end': false,
      'list': []
    }
  };

  type = '';

  constructor(
    private apiService: ApiService,
  ) {
    this.manufacturer_options = {
      dropdownAutoWidth: true,
      maximumSelectionLength: 200,
      placeholder: 'メーカー',
      width: '200px',
    };
    this.item_options = {
      dropdownAutoWidth: true,
      maximumSelectionLength: 200,
      placeholder: '機種',
      width: '200px',
      disabled: true
    };
    this.manufacturer_id = '';
    this.score_type = 'up';
    this.item_code = '';
    this.item_code_by_selectbox = '';
  }

  render = (values: any) => {
    const item = this.makers[this.type];
    item['list'] = [];

    for (const [key, value] of Object.entries(values)) {
      item['list'].push(
        {
          'id': key,
          'text': value['maker_name'],
          'item': value['item_list']
        }
      );
    }

    sortDict(item['list'], 'text');
    item['list'].unshift(
      {
        'id': '',
        'text': 'メーカー',
        'item': []
      }
    );
  }

  /**
   * 機種一覧取得
   * @param type camera | lens
   */
  getItmes(type: string): Promise<any> {
    let path: string;
    this.type = type;
    if (type === 'camera') {
      path = 'item/cameras';
    } else {
      path = 'item/lens';
    }
    return this.apiService.getApi(path)
      .then(this.render);
  }

  /**
   * メーカーのセレクトボックス切り替え時、機種一覧の修正
   * @param manufacturer_id 相場表コード
   * @param type camera|lens
   */
  changeItem(manufacturer_id: string, type: string) {
    this.items = [];
    this.item_options.disabled = true;

    const findByManufacturerId = (element: any) => {
      if (manufacturer_id && element.id === manufacturer_id) {
        element.item.forEach(
          item => {
            this.items.push({ id: item['item_code'], text: item['item_name'] });
          }
        );
        this.item_options.disabled = false;
        return true;
      }
    };

    if (type === 'camera') {
      this.makers['camera']['list'].find(findByManufacturerId);
    } else {
      this.makers['lens']['list'].find(findByManufacturerId);
    }
    sortDict(this.items, 'text');
    this.items.unshift({ id: '', text: '選択なし' });
    this.manufacturer_id = manufacturer_id;
  }

  /**
   * 機種で検索/精度で検索
   * 1:機種で検索 , 2:精度で検索
   */
  changeGroup(type: number) {
    this.viewGroup = type;
  }

  /**
   * 「クリア」ボタン押下時
   * 検索フォームの入力内容を空にする
   */
  clear() {
    this.item_code = '';
    this.item_code_by_selectbox = '';
    this.manufacturer_id = '';
    this.score = null;
    this.score_type = 'up';
  }

  selectItem(item_code_by_selectbox: string) {
    this.item_code_by_selectbox = item_code_by_selectbox;
  }

  valid_parameters(page_type: string) {
    const parameters = {};
    let error = null;
    if (this.viewGroup === 1) {
      // 相場表コードが存在すれば最優先
      if (this.item_code) {
        this.manufacturer_id = '';
        this.item_code_by_selectbox = '';
        parameters['item_code'] = this.item_code;
      }
      // 機種選択がされている場合、相場表コードがセレクトボックスにのvalueになっている。
      if (this.item_code_by_selectbox) {
        parameters['item_code'] = this.item_code_by_selectbox;
      }
      // メーカーコードが存在する
      if (this.manufacturer_id) {
        parameters['manufacturer_id'] = this.manufacturer_id;
      }
      // 検索項目が空の場合は検索できない。
      if (isEmpty(parameters)) {
        error = ErrorMessage('E0104');
      }
    } else {
      // メーカーコードが存在する
      if (this.manufacturer_id) {
        parameters['manufacturer_id'] = this.manufacturer_id;
      }
      // 以上 or 以下
      if (this.score_type) {
        parameters['score_type'] = this.score_type;
      }
      // スコア値。数値であること
      if (this.score) {
        const numeric_score = this.score / 100;
        if (isNaN(numeric_score)) {
          error = ErrorMessage('E0202');
        }
        parameters['score'] = numeric_score;
      }
      // メーカーコード、スコア値が空の場合は検索できない。
      if (!this.manufacturer_id && !this.score) {
        error = ErrorMessage('E0104');
      }
    }
    parameters['type'] = page_type;
    return { error, parameters };
  }
}
