import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '@services/auth.service';

/**
 * 認証確認ガード
 */
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    /**
     * 認証状態を確認する
     * @param next 次のルーティング先
     * @param state ルータの状態
     */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isAuthenticated()
            .pipe(
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigate(['/login']);
                    }
                    const self = this;
                    this.authService.getCurrentUserRole().catch(
                        () => {
                            self.router.navigate(['/login']);
                        }
                    );
                })
            );
    }
}
