import { Injectable } from '@angular/core';
import numeral from 'numeral';
import { Debug } from '@lib/log';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }
  public options = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      yAxes: [{
        ticks: {
          max: 100,
          min: 0,
          stepSize: 50
        }
      }],
    },
  };
  public chartType = 'line';
  public legend = true;
  public colors = [
    {
      backgroundColor: 'rgba(60, 160, 220, 0.3)',
      borderColor: 'rgba(60, 160, 220, 0.8)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      lineTension: 0,
      spanGaps: true
    }
  ];

  /**
   * 少数をパーセント表示に変更する。
   */
  numToPercent = (value) => {
    if (value !== '') {
      return numeral(value * 100).format('0.0');
    }
  }

  /**
   * 日付フォーマット yyyy/mm/dd の　月　日だけを取得する。
   */
  split_time = (value: string) => {
    const split_tmp = value.split('-');
    return split_tmp[1] + '/' + split_tmp[2];
  }

  mappingDetail(datas, key: string) {
    Debug().log(datas);
    const result = [];
    Object.values(datas).forEach(
      ele => {
        const tmp = {};
        const dataArray = Object.values(ele['score_histories']).map(this.numToPercent);
        tmp['chartdata'] = [{
          data: dataArray,
          label: '精度',
        }];

        // 最も新しい数値が格納されているデータを最新の値として取得する。
        tmp['last_data'] = 0;
        tmp['last_data'] = dataArray.concat().reverse().find(
          data => {
            if (data !== '') {
              return data;
            }
          }
        );
        tmp['chartheader'] = Object.keys(ele['score_histories']).map(this.split_time);
        tmp['image_path'] = ele['image_path'];
        tmp['name'] = ele['name'];
        result.push(tmp);
      }
    );
    return result;
  }

  mappingList(datas, key: string) {
    Debug().log(datas);
    datas.forEach(
      ele => {
        ele.chartheader = Object.keys(ele[key]).map(this.split_time);
        ele.chartdata = [{
          data: Object.values(ele[key]).map(this.numToPercent),
          label: '精度',
        }];
      }
    );
  }
}
