import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  image: string;
  constructor(
    public modalRef: MDBModalRef
  ) {
  }

  ngOnInit() {
  }

}
