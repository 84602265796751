
/**
 * 顧客環境 AWS Amplify(Auth)の設定
 */
export const environment = {
    production: false,
    dev: false,
    amplify: {
        Auth: {
            identityPoolId: 'us-west-2:db863e40-0ef7-4e8d-b85f-d1c39f2fe59d',
            region: 'us-west-2',
            userPoolId: 'us-west-2_nypMDA85Y',
            userPoolWebClientId: '2enfil8bf8hlnocsgbcu13hc70',
            storage: window.sessionStorage,
        },
        API: {
            endpoints: [
                {
                    name: 'API',
                    endpoint: 'https://chukoai-api-dev.kitamura.jp',
                    region: 'us-west-2'
                }
            ]
        }
    }
};
