import { Component, OnInit, Injectable } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Debug } from '@lib/log';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  title: string;
  body: string;
  is_action: boolean;
  button: string;

  constructor(
    public modalRef: MDBModalRef) {
  }

  ngOnInit() {
    Debug().log(this);
  }
  action = () => {
    Debug().log('over ride');
  }
}
