import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import Amplify, { Auth, API } from 'aws-amplify';
import { LoginUser } from '@entity/loginuser';
import { Router } from '@angular/router';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Debug } from '@lib/log';


/**
 * 認証サービス
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * ログイン済かどうかを表すフラグ
     */
    public loggedIn: BehaviorSubject<boolean>;

    public username: string;

    constructor(
        private router: Router
    ) {
        Amplify.configure(environment.amplify);
        this.loggedIn = new BehaviorSubject<boolean>(false);
    }

    /** set token to check if the user is logged in or not */
    setChangePasswordToken(token: string) {
        localStorage.setItem('LoggedInToken', token);
    }

    /**
     * Amplifyのログイン機能をキックする。
     * @param id cogniteのID
     * @param password　cogniteのパスワード
     */
    public logIn(id, password): Observable<any> {
        return from(Auth.signIn(id, password))
            .pipe(
                tap(() => this.loggedIn.next(true))
            );
    }

    /**
     * ログインしているかを返却する
     */
    public isAuthenticated(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser())
            .pipe(
                map(result => {
                    this.loggedIn.next(true);
                    return true;
                }),
                catchError(error => {
                    this.loggedIn.next(false);
                    return of(false);
                })
            );
    }

    /**
     * Amplifyにユーザー情報を問い合わせる
     */
    public getCurrentUserInfo(): Promise<any> {
        return Auth.currentUserInfo();
    }

    /**
      * Amplifyにセッション情報を問い合わせる
      */
    public getCurrentSession(): Promise<CognitoUserSession> {
        return Auth.currentSession();
    }

    /**
     * localStorage内のログイン情報を破棄する。
     */
    public logOut(): Observable<any> {
        return from(Auth.signOut())
            .pipe(
                tap(() => {
                    localStorage.removeItem('LoggedInToken');
                    this.loggedIn.next(false);
                })
            );
    }

    public getCurrentUserRole(): Promise<any> {
        return Auth.currentUserInfo()
            .then(this.getCurrentUserRoleIn);
    }

    public getCurrentUserRoleIn(row_value: any): Promise<any> {
        return new Promise(function (resolve, reject) {

            // ログイン期限切れの場合
            if (!row_value.hasOwnProperty('username')) {
                reject('error');
            }

            if (row_value && 'username' in row_value) {
                const loginuser = new LoginUser;
                loginuser.username = row_value.username;
                resolve(loginuser);
            } else {
                reject('error');
            }
        });
    }


    /**
     * Password Change
     * @param oldPassword currrent user password
     * @param newPassword updated new password
     * @param email to be used for only initial password change
     */
    public passwordChange(oldPassword: string, newPassword: string, email: string): Observable<any> {
        if (email) {
            Auth.signIn(email, oldPassword).then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    return from(Auth.completeNewPassword(user, newPassword, {}));
                }
            }, error => {
                Debug().log(error);
            });
            return new Observable(null);
        } else {
            return from(Auth.currentAuthenticatedUser().then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            }));
        }
    }
}
