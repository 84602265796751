import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { LoginUser } from '@entity/loginuser';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  old_password: string;

  new_password: string;

  confirm_password: string;

  error_message: string;

  complet_message: string;

  password_valid_pattern = '^(?=.*?[a-z])(?=.*?\\d)(?=.*?[@!$%*#?&\-_])[a-z\\d@!$%*#?&\-_]{8,}$';

  constructor(
    private authService: AuthService,
    public loginUser: LoginUser,
    private loading: LoadingService
  ) {
  }

  ngOnInit() {
  }

  /**
   * パスワード変更
   */
  passwordChange(): void {
    this.loading.start();
    if (!this.old_password || !this.new_password || !this.confirm_password) {
      this.error_message = '数字、アルファベット、記号を含む8文字以上で入力してください。';
      this.loading.end();
      return;
    }
    if (this.new_password !== this.confirm_password) {
      this.error_message = '入力されたパスワードが違います';
      this.loading.end();
      return;
    }
    this.authService.passwordChange(this.old_password, this.new_password, null)
      .subscribe(
        () => {
          this.error_message = null;
          this.complet_message = 'パスワードの変更が完了しました。';
        },
        error => {
          this.passwordChangeErrorHander(error);
        }
      ).add(this.loading.loading_end);
  }

  /**
   * エラーメッセージのハンドリング
   * @param error coginto エラー
   */
  passwordChangeErrorHander(error: any) {
    if (error.code === 'InvalidParameterException' || error.code === 'InvalidPasswordException') {
      this.error_message = '数字、アルファベット、記号を含む8文字以上で入力してください。';
    } else if (error.code === 'LimitExceededException') {
      this.error_message = '試行回数の上限を超えました。しばらくしてからもう一度お試しください。';
    } else if (error.code === 'NotAuthorizedException') {
      this.error_message = 'パスワードが間違っています。';
    } else {
      this.error_message = 'パスワードの変更に失敗しました。';
    }
  }
}

