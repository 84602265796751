import { Injectable } from '@angular/core';

export interface Role {
    id: string;
    name: string;
}

export interface Attributes {
    name: string;
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class LoginUser {

    username: string;
    attributes: Attributes;

    constructor(
    ) {
    }

    roundUserName() {
        const count = 50;
        if (!this.username || this.username.length < count) {
            return this.username;
        }
        return this.username.substr(0, (count)) + ' …';
    }
}
