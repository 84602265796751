import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { Debug } from '@lib/log';
import { ErrorMessage } from '@entity/errorMessage';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    user_id: string;
    password: string;
    error_message: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthService,
        private loading: LoadingService
    ) { }

    ngOnInit() {
        this.auth.logOut();
        const error_code = this.route.snapshot.queryParams['error'];
        if (error_code) {
            this.error_message = ErrorMessage(error_code);
        }
    }

    login(loginCount = 0): void {

        /** ログイン処理最大トライ回数 */
        const MAX_LOGIN_TRY_COUNT = 3;

        this.loading.start();
        if (!this.user_id || !this.password) {
            this.loading.end();
            return;
        }
        // ログイン処理
        this.auth.isAuthenticated().subscribe(
            loggedIn => {
                if (!loggedIn) {
                    this.auth.logIn(this.user_id, this.password)
                        .subscribe(
                            result => {
                                this.router.navigate(['/']);
                            },
                            error => {
                                Debug().log(error);
                                if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
                                    this.error_message = 'ユーザーIDまたはパスワードが違います。';
                                } else {
                                    this.error_message = 'サーバとの通信が失敗しました。';
                                }
                                this.loading.end();
                            }
                        );
                } else {
                    // ログイン状態のため、一度ログアウトをする。
                    this.auth.logOut().subscribe(
                        () => {
                            if (loginCount < MAX_LOGIN_TRY_COUNT) {
                                this.login(loginCount++);
                            } else {
                                this.error_message = '正常にログイン出来ませんでした。';
                                this.loading.end();
                            }
                        },
                        () => {
                            this.error_message = 'サーバとの通信が失敗しました。';
                            this.loading.end();
                        }
                    );
                }
            },
            () => {
                this.error_message = 'サーバとの通信が失敗しました。';
                this.loading.end();
            }
        );
    }
}
