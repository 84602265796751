import { Injectable } from '@angular/core';
import { sortDict, isEmpty } from '@lib/lib';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  // データが存在しない場合、リストを非表示とする
  datas: any;
  row_datas: any;
  previous: any;
  page: number;
  limit: number;
  page_limit: number;
  select_sort: string;
  select_order: string;

  // 一つのpager機能を使用し、カメラ・レンズが切り替わる場合
  data_type: string;

  constructor() {
    this.page = 1;
  }

  /**
   * 連番を持たせる
   */
  setSerialNumber() {
    let count = 1;
    this.row_datas.forEach(
      ele => {
        ele.no = count;
        count = count + 1;
      });
  }

  /**
   * ページフッターボタン表示
   */
  countData() {
    let max_count = 0;
    let page_start = 0;
    let page_to = 0;
    if (!isEmpty(this.datas)) {
      max_count = this.datas.length;
    } else {
      return '0 件中 ( 0 - 0 表示 )';
    }
    page_start = this.limit * (this.page - 1) + 1;
    if (max_count < page_start + this.limit) {
      page_to = max_count;
    } else {
      page_to = this.page * this.limit;
    }
    return max_count + '件中 ( ' + page_start + ' - ' + page_to + ' 表示 )';
  }

  init(limit: number) {
    this.limit = limit;
    this.page = 1;
    this.select_sort = '';
    this.select_order = 'desc';
    this.sort('item_code', this.row_datas);
    this.setSerialNumber();
    this.datas = Object.assign([], this.row_datas);
    this.setPage(1);
    this.previous = this.datas.slice(0, this.page * limit);
    this.page_limit = Math.ceil(this.datas.length / limit);
  }

  /**
   * テーブルの中身を指定のページ番号のデータにする
   * @param page ページ
   */
  setPage(page: number) {
    this.previous = this.datas.slice((page - 1) * this.limit, page * this.limit);
    this.page = page;
  }

  /**
   * テーブルヘッダのソートボタン押下時処理。
   * @param sort_key ソートキー
   */
  sort(sort_key: string, datas) {
    // API取得時のデータにする
    this.setSortStatus(sort_key);
    sortDict(datas, this.select_sort, this.select_order);
  }

  /**
   * 並び替え内容の決定。
   * 2度押し: asc desc の切り替え
   * 別のカラム押し: ascにしてソート
   * @param sort_key ソートキー
   */
  setSortStatus(sort_key: string) {
    // 初回
    if (this.select_sort === '') {
      this.select_sort = sort_key;
      this.select_order = 'asc';
    }
    if (this.select_sort === sort_key) {
      // 前回と同じソートキーを選択した場合、asc/desc切り替える
      if (this.select_order === 'desc') {

        this.select_order = 'asc';
      } else {
        this.select_order = 'desc';
      }
    } else {
      // 前回と違うソートキーを選択した場合、強制descに切り替える
      this.select_sort = sort_key;
      this.select_order = 'asc';
    }
  }

  /**
   * クリアボタン押下時、検索項目を空にする
   */
  clear() {
    this.previous = null;
    this.datas = null;
    this.select_sort = '';
    this.select_order = '';
  }

}
