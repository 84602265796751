import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from '@screen/camera/list/list.component';
import { DetailComponent } from '@screen/camera/detail/detail.component';
import { ListComponent as LensListComponent } from '@screen/lens/list/list.component';
import { DetailComponent as LensDetailComponent } from '@screen/lens/detail/detail.component';
import { ChangePasswordComponent } from '@screen/auth/changepassword/changepassword.component';
import { LoginComponent } from '@screen/auth/login/login.component';
import { AuthGuard } from '@guard/auth.guard';

/**
 * ベースのルーティング定義。ログイン以降のルーティングはbase配下で別途定義する
 **/
const routes: Routes = [
    {
        path: 'login', component: LoginComponent
    },
    {
        path: 'changepassword', component: ChangePasswordComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '', component: ListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'camera/:id', component: DetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'lens', component: LensListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'lens/:id', component: LensDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**', redirectTo: '/login?error=E0102'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
