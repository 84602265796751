
export function ErrorMessage(code: string) {
    return new ErrorHandlerClass(code).message();
}

export class ErrorHandlerClass {

    constructor(public code: string) {
    }
    errormessage = {
        E0102: '該当のページが存在しません。',
        E0103: '該当のデータが存在しません。',
        E0104: '検索内容を入力してください。',
        E0202: '精度は数値のみ入力できます。',
        E0203: '精度の入力は必須です。',
        E0204: 'ユーザーIDが重複しています。',
        E0205: 'サービスエラー。管理者にお問い合わせください。',
        E0212: 'パスワードは8文字以上で、小文字・数字・記号を含める必要があります。',
        E0303: '該当のデータが存在しません。',
        E0403: '該当のデータが存在しません。'
    };

    public message() {
        if (this.code in this.errormessage) {
            return this.errormessage[this.code];
        } else {
            return '該当のデータが存在しません。';
        }
    }
}

