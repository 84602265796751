
import { from } from 'rxjs';
import { Auth, API } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Debug } from '@lib/log';

interface Inits {
    headers: {
        'Authorization': string;
        'Content-Type': string;
    };
    body?: {};
    queryStringParameters?: {};
}

@Injectable({
    providedIn: 'root',
})

export class ApiService {

    constructor(
    ) { }

    base(session: CognitoUserSession): Promise<Inits> {

        const myInit: Inits = {
            headers: {
                'Authorization': session.getIdToken().getJwtToken(),
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        return new Promise((resolve) => {
            resolve(myInit);
        });
    }

    delApi = (path: string, body: {} = null) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession().then(this.base)
                .then(function (myInit: Inits) {
                    myInit.body = body;
                    from(API.del('API', '/' + path, myInit)).subscribe(
                        response => {
                            Debug().log(response);
                            resolve(response);
                        },
                        error => {
                            Debug().error(error);
                            reject(error);
                        }
                    );
                });
        });
    }

    postApi = (path: string, body: {}) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession().then(this.base)
                .then(function (myInit: Inits) {
                    myInit.body = body;
                    from(API.post('API', '/' + path, myInit)).subscribe(
                        response => {
                            Debug().log(response);
                            resolve(response);
                        },
                        error => {
                            Debug().error(error);
                            reject(error);
                        }
                    );
                });
        });
    }

    getApi = (path: string, queryStringParameters: {} = null) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession().then(this.base)
                .then(function (myInit: Inits) {
                    if (queryStringParameters) {
                        myInit.queryStringParameters = queryStringParameters;
                    }

                    from(API.get('API', '/' + path, myInit)).subscribe(
                        response => {
                            Debug().log(response);
                            resolve(response);
                        },
                        error => {
                            Debug().error(error);
                            reject(error);
                        }
                    );
                });
        });
    }

    putApi = (path: string, body: {}) => {
        return new Promise((resolve, reject) => {
            Auth.currentSession().then(this.base)
                .then(function (myInit: Inits) {
                    myInit.body = body;
                    from(API.put('API', '/' + path, myInit)).subscribe(
                        response => {
                            Debug().log(response);
                            resolve(response);
                        },
                        error => {
                            Debug().error(error);
                            reject(error);
                        }
                    );
                });
        });
    }
}
