import { Injectable } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalRef: MDBModalRef;

  constructor(
    private mdbModalService: MDBModalService,
  ) {
  }

  /**
   * モーダルの作成
   * @param component モーダル内部で利用するコンポーネント
   * @param option 設定ファイル
   */
  create(component: any, option = {}) {
    this.modalRef = this.mdbModalService.show(component, option);
  }

  /**
   * モーダル内部で利用するcomponentに引数を渡す場合、object形式にして、パラメータを渡す。
   * @param param 変数渡し
   */
  set(param: object) {
    for (const [key, value] of Object.entries(param)) {
      this.modalRef.content[key] = value;
    }
  }

  /**
   * ボタン等のアクションの押下時
   * @param action any
   */
  action(action: any) {
    this.modalRef.content.action = action;
  }

  /**
   * モーダルを閉じる
   */
  end() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
