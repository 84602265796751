import * as Moment from 'moment';

// 空判定
export const isEmpty = <T>(value: T): boolean => {

  if (typeof value === 'undefined') {
    return true;
  }

  if (typeof value === 'object' && 0 === Object.keys(value).length) {
    return true;
  }
  return false;
};


// リロード
export const Reload = () => {
  window.location.reload();
};

// 日付処理
export const DateToString = (time: number = null): string => {
  if (time) {
    return Moment().add(Number(time), 'days').format('YYYY-MM-DD')
  } else {
    return Moment().format('YYYY-MM-DD');
  }
};


export const getImageSize = (path) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = path;
  });
};

/**
 * ソート機能
 * @param dict ソートする配列
 * @param key ソートキー
 * @param order asc | desc
 */
export const sortDict = (dict: any[], key: string, order: string = 'desc') => {
  dict.sort(function (a, b) {
    const nameA = a[key];
    const nameB = b[key];

    if (typeof a.no === 'string') {
      nameA[key].toUpperCase();
      nameB[key].toUpperCase();
    }
    if (order === 'asc') {
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    } else {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  });
};
